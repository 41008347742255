import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import type { HLocation } from '@reach/router';
import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import { Link } from 'gatsby';

import { nanoid } from 'nanoid';
import * as React from 'react';

import { classNames } from '../../utils/classnames';
import { Layout } from '../../components/layout';
import { ContactSection } from '../../components/modules/contact-section';
import { Hero } from '../../components/modules/hero';
import { SEO } from '../../components/seo';
import type { ISanityPost } from '../../types';

interface PostPageProps {
  data: {
    sanityPost: ISanityPost;
    allSanityPost: {
      edges: [
        {
          previous: ISanityPost;
          next: ISanityPost;
          node: ISanityPost;
        }
      ];
    };
  };
  location: HLocation;
}

function PostPage({ data, location }: PostPageProps): JSX.Element {
  const { sanityPost, allSanityPost } = data;

  const currentPost = allSanityPost.edges.find(
    (post) => post.node.id === sanityPost.id
  );

  const currentPostIndex = allSanityPost.edges.findIndex(
    (post) => post.node.id === sanityPost.id
  );
  const nextPosts = [
    allSanityPost.edges[currentPostIndex + 1],
    allSanityPost.edges[currentPostIndex + 2],
  ].filter((item) => item != undefined);

  const previousPosts = [
    allSanityPost.edges[currentPostIndex - 1],
    allSanityPost.edges[currentPostIndex - 2],
  ].filter((item) => item != undefined);

  return (
    <>
      <SEO
        title={sanityPost.title}
        image={sanityPost.seo?.shareGraphic?.asset.url}
        pathname={location.pathname}
      />
      <Layout>
        <Hero
          hero={{
            id: nanoid(),
            _type: 'hero',
            heading: [
              {
                id: nanoid(),
                isBold: true,
                text: 'OUR BLOGS',
              },
            ],
            ctas: [
              {
                id: 'e46adfe806ef',
                _type: 'pageCta',
                page: {
                  _type: 'page',
                  slug: {
                    current: 'contact-us',
                  },
                },
                text: 'Contact us',
                isButton: true,
                styles: {
                  isBlock: false,
                  isLarge: true,
                  style: 'is-primary',
                },
              },
            ],
            backgroundImage: {
              alt: 'port-macquarie-contact-us',
              asset: {
                gatsbyImageData: {
                  images: {
                    sources: [],
                    fallback: {
                      src: 'https://cdn.sanity.io/images/j3qg7m62/production/a9658106d50ebee268826c1a3d27f3a41f104b9c-746x365.png?w=746&h=365&auto=format',
                      srcSet:
                        'https://cdn.sanity.io/images/j3qg7m62/production/a9658106d50ebee268826c1a3d27f3a41f104b9c-746x365.png?rect=1,0,744,365&w=320&h=157&auto=format 320w,\nhttps://cdn.sanity.io/images/j3qg7m62/production/a9658106d50ebee268826c1a3d27f3a41f104b9c-746x365.png?w=654&h=320&auto=format 654w,\nhttps://cdn.sanity.io/images/j3qg7m62/production/a9658106d50ebee268826c1a3d27f3a41f104b9c-746x365.png?w=746&h=365&auto=format 746w',
                      sizes: '(min-width: 746px) 746px, 100vw',
                    },
                  },
                  layout: 'constrained',
                  backgroundColor: '#d7a58d',
                  width: 1920,
                  height: 939,
                },
              },
              customRatio: 'sixteenByNine',
              hotspot: null,
            },
          }}
        />
        <div className="bg-gradient-to-b from-secondary to-tertiary">
          <article className="grid w-full mx-auto lg:grid-cols-5 max-w-prose lg:max-w-screen-2xl sm:px-6 lg:px-8">
            <div className="px-4 py-12 space-y-6 lg:px-0 lg:col-span-3 lg:pr-12 lg:py-24 ">
              <h2 className="font-semibold text-light">
                <span className="inline-block text-2xl leading-tight uppercase">
                  {sanityPost.title}
                </span>
              </h2>
              <BlockContent
                renderContainerOnSingleChild
                blocks={sanityPost._rawExcerpt}
                className="prose prose-dark"
              />
              <div className="flex flex-shrink-0">
                {sanityPost.mainImage ? (
                  <GatsbyImage
                    image={sanityPost.mainImage.asset.gatsbyImageData}
                    alt={sanityPost.mainImage.alt || ''}
                    className="h-full"
                  />
                ) : null}
              </div>
              <BlockContent
                renderContainerOnSingleChild
                blocks={sanityPost._rawBody}
                className="prose prose-dark"
              />
              <div>
                <p className="font-semibold text-white">
                  <time dateTime={sanityPost.publishedAtISO} className="mr-2">
                    {moment(sanityPost.publishedAt).format('MMMM Do')}
                  </time>
                  |<span className="ml-2">{sanityPost.author}</span>
                </p>
              </div>

              <nav className="flex items-center justify-between px-4 mt-12 sm:px-0">
                <div className="flex justify-start flex-1 w-0 -mt-px">
                  <Link
                    to={
                      currentPost?.previous
                        ? '/blog/' + currentPost?.previous?.slug?.current
                        : ''
                    }
                    className={classNames(
                      !currentPost?.previous &&
                        'pointer-events-none opacity-60',
                      'inline-flex items-center pt-4 pb-2 pr-1 text-sm font-medium border-t-2 border-transparent text-white hover:text-white hover:border-white'
                    )}
                  >
                    <ChevronLeftIcon
                      className="w-5 h-5 mr-2 text-white"
                      aria-hidden
                    />
                    |<span className="ml-2">Previous Blog</span>
                  </Link>
                </div>

                <div className="flex justify-end flex-1 w-0 -mt-px">
                  <Link
                    to={
                      currentPost?.next
                        ? '/blog/' + currentPost?.next?.slug?.current
                        : ''
                    }
                    className={classNames(
                      !currentPost?.next && 'pointer-events-none  opacity-60',
                      'inline-flex items-center pt-4 pb-2 pl-1 text-sm font-medium text-white border-t-2 border-transparent hover:text-white hover:border-white'
                    )}
                  >
                    <span className="mr-2">Next Blog</span> |
                    <ChevronRightIcon
                      className="w-5 h-5 ml-2 text-white"
                      aria-hidden
                    />
                  </Link>
                </div>
              </nav>
            </div>
            <div className="px-8 py-12 space-y-6 bg-white lg:col-span-2 lg:py-24">
              <h2 className="font-semibold text-tertiary">
                <span className="inline-block text-2xl leading-tight uppercase">
                  Up next
                </span>
              </h2>

              <div className="space-y-8">
                {nextPosts.map((post, index) => (
                  <div key={post.node.title} className="grid overflow-hidden">
                    <div
                      className={`flex flex-shrink-0 aspect-w-16 aspect-h-9 relative clip-polygon-b-r`}
                    >
                      <Link
                        aria-hidden
                        tabIndex={-1}
                        to={`/blog/${post.node.slug.current}/`}
                        className="absolute inset-0 flex"
                      >
                        {post.node.mainImage ? (
                          <GatsbyImage
                            image={post.node.mainImage.asset.gatsbyImageData}
                            alt={post.node.mainImage.alt || ''}
                            className="flex-1"
                          />
                        ) : null}
                      </Link>
                    </div>
                    <div
                      className={`flex flex-col justify-between flex-1 py-6 `}
                    >
                      <div className="flex-1">
                        <Link
                          to={`/blog/${post.node.slug.current}/`}
                          className="block mt-2"
                        >
                          <p className="text-2xl font-semibold uppercase">
                            {post.node.title}
                          </p>
                          <BlockContent
                            renderContainerOnSingleChild
                            blocks={post.node._rawExcerpt}
                            className="mt-3 prose-lg"
                          />
                        </Link>
                      </div>
                      <div className="mt-4">
                        <p className="font-semibold">
                          <time
                            dateTime={post.node.publishedAtISO}
                            className="mr-2"
                          >
                            {moment(post.node.publishedAt).format('MMMM Do')}
                          </time>
                          |<span className="ml-2">{post.node.author}</span>
                        </p>
                      </div>
                      <p className="mt-6 text-sm font-medium">
                        <Link
                          aria-hidden
                          tabIndex={-1}
                          to={`/blog/${post.node.slug.current}/`}
                          className="uppercase hover:underline"
                        >
                          Read more
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
                {previousPosts.map((post, index) => (
                  <div key={post.node.title} className="grid overflow-hidden">
                    <div
                      className={`flex flex-shrink-0 aspect-w-16 aspect-h-9 relative clip-polygon-b-r`}
                    >
                      <Link
                        aria-hidden
                        tabIndex={-1}
                        to={`/blog/${post.node.slug.current}/`}
                        className="absolute inset-0 flex"
                      >
                        {post.node.mainImage ? (
                          <GatsbyImage
                            image={post.node.mainImage.asset.gatsbyImageData}
                            alt={post.node.mainImage.alt || ''}
                            className="flex-1"
                          />
                        ) : null}
                      </Link>
                    </div>
                    <div
                      className={`flex flex-col justify-between flex-1 py-6 `}
                    >
                      <div className="flex-1">
                        <Link
                          to={`/blog/${post.node.slug.current}/`}
                          className="block mt-2"
                        >
                          <p className="text-2xl font-semibold uppercase">
                            {post.node.title}
                          </p>
                          <BlockContent
                            renderContainerOnSingleChild
                            blocks={post.node._rawExcerpt}
                            className="mt-3 prose-lg"
                          />
                        </Link>
                      </div>
                      <div className="mt-4">
                        <p className="font-semibold">
                          <time
                            dateTime={post.node.publishedAtISO}
                            className="mr-2"
                          >
                            {moment(post.node.publishedAt).format('MMMM Do')}
                          </time>
                          |<span className="ml-2">{post.node.author}</span>
                        </p>
                      </div>
                      <p className="mt-6 text-sm font-medium">
                        <Link
                          aria-hidden
                          tabIndex={-1}
                          to={`/blog/${post.node.slug.current}/`}
                          className="uppercase hover:underline"
                        >
                          Read more
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </article>
        </div>
        <ContactSection
          contactSection={{
            id: nanoid(),
            _type: 'contactForm',
            showContactInfo: true,
            disableMap: false,
          }}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query PostPageQuery($slug__current: String!) {
    sanityPost(slug: { current: { eq: $slug__current } }) {
      id
      _type
      _rawBody
      _rawExcerpt
      author
      mainImage {
        alt
        asset {
          gatsbyImageData(width: 1920)
        }
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      publishedAtISO: publishedAt
      seo {
        metaDesc
        metaTitle
        shareDesc
        shareGraphic {
          asset {
            url
          }
        }
        shareTitle
      }
      slug {
        current
      }
      title
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        next {
          id
          _rawExcerpt
          author
          mainImage {
            alt
            asset {
              gatsbyImageData(width: 400)
            }
          }
          slug {
            current
          }
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          publishedAtISO: publishedAt
        }
        previous {
          id
          _rawExcerpt
          author
          mainImage {
            alt
            asset {
              gatsbyImageData(width: 400)
            }
          }
          slug {
            current
          }
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          publishedAtISO: publishedAt
        }
        node {
          id
          _rawExcerpt
          author
          mainImage {
            alt
            asset {
              gatsbyImageData(width: 400)
            }
          }
          slug {
            current
          }
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          publishedAtISO: publishedAt
        }
      }
    }
  }
`;

export default PostPage;
